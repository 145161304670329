<template>
    <div class="big-b">
        <div class="bro">
            <h2>BECOME DIPLOMATS OF TOMORROW</h2>
        </div>
        <v-btn id="bbtn" target='_blank' href="https://docs.google.com/forms/d/1D_xM5ecoxGhk-J9c5hjEzSYOjcOOUvpgDhgnDs9onoM/edit"  variant="outlined" class="text-white" size="large">REGISTER NOW AS DELEGATE</v-btn>
    </div>
</template>


<style>
  @import './FourthPage.css';
</style>