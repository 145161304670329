<template>
    <section class="second  px-4 mx-4 mb-4">
      <div class="wrapper">
        <v-img router :to="`/pimun2019`" class="img-bg" cover src="https://i.ibb.co/9t17tw9/pimuncollage.png">

        </v-img>
        <div class="img-info">
          <div class="pt-4">
            <div class="aboutus font-weight-black">OUR LAST EVENT</div>
            <p class="pimun font-weight-black">PIMUN 2019</p>
          </div>
          <article class="about pt-3">
            <p>In 2019, Chittagong witnessed a groundbreaking event as Presidency International School organised its first-ever Model United Nations conference, the Presidency International Model United Nations 2019. This landmark event brought together approximately 350 delegates from Chittagong, Dhaka, and even overseas, creating a platform for meaningful discussions and diplomatic simulations.<br><br>
            The PIMUN 2019 conference, held at Presidency International School's campus, not only marked a significant milestone for the school but also left an indelible mark on the MUN circuit in Chittagong. The ambiance and the culinary offerings provided during the conference set a new benchmark for MUN events in the city. The organizing committee left no stone unturned to ensure that the delegates experienced a seamless and memorable event.
            <br><br>
            Over the course of three days, delegates from various schools and colleges engaged in intense debates and discussions within numerous committees. The committees, designed to mimic the real United Nations organs and agencies, covered a wide range of topics spanning political, social, economic, and environmental issues. The lively debates and passionate exchanges generated a sensation throughout the conference, stimulating critical thinking and fostering intellectual growth among the participants.
            <br></p>
          </article>
          <div class="btnwrap pt-4">
                <v-btn router target='_blank' to="/pimun2019" class="font-weight-black" variant="outlined">View Gallery</v-btn>
            </div>
        </div>
      </div>
    </section>
  </template>

<style>
  @import './FifthPage.css';

</style>