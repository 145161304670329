<template>
  <section class="second  px-4 mx-4 mb-4">
    <div class="wrapper">
      <v-img class="img-bg" cover src="https://i.ibb.co/VTbWDGC/bg-page.jpg"></v-img>
      <div class="img-info">
        <div class="pt-4">
          <div class="aboutus font-weight-black">ABOUT US</div>
          <p class="pimun font-weight-black">PIMUN 2023</p>
        </div>
        <article class="about pt-3">
          <p>Presidency MUN Club is exhilarated to announce the comeback of our signature event after four long years! We are here again to organize our second and latest edition – Presidency International Model United Nations 2023. We strive to be the best while allowing our peers to compete on an international scale and pushing them to go above and beyond their limits as delegates. The event is scheduled to be hosted from 31st August – 2nd September, 2023 in Presidency International School in the heart of the economic hub of Bangladesh.<br>  </p>
          <p><br>Bearing risen tensions, we have set “Cooperation and Collaboration for pursuing World Peace” as the theme of the event. Presidency International MUN is ready to set benchmarks in the MUN circuit, through engaging young diplomats where we’ll be expecting the delegates to delve into debates about world issues. It gives the PIMUN Secretariat Board immense pleasure to officially welcome you to this awaited occasion! We are only getting started.</p>
        </article>
      </div>
    </div>
  </section>
</template>


<style>
  @import './SecondPage.css';
</style>